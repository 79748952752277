import { Link } from "gatsby"
import React from "react"
import { Layout } from "../components/global"
import { Helmet } from "react-helmet"

const error = () => {
  return (
    <Layout>
      <Helmet>
        <title>Keyla Technologies - Page not found</title>
        <meta name="robots" content="noIndex" />
      </Helmet>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          minHeight: "100vh",
          textAlign: "center",
        }}
      >
        <div>
          <h1>We couldn't find the page you're looking for</h1>
          <br />
          <Link to="/">Go back</Link>
        </div>
      </div>
    </Layout>
  )
}

export default error
